<template>
  <b-modal
    ref="setting-round-modal"
    hide-footer
    title="ตั้งค่า"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="วันที่"
      >
        <flat-pickr
          id="round-date"
          :config="{enableTime: false, enableSeconds: true, time_24hr: true, dateFormat: 'Y-m-d'}"
          v-model="input.roundDate.date"
          class="form-control"
        />
      </b-form-group>

      <b-form-group
        label="เวลาเปิดรับแทง"
      >
        <flat-pickr
          id="round-open-time"
          :config="{enableTime: true, enableSeconds: false, time_24hr: true, dateFormat: 'Y-m-d H:i:S'}"
          v-model="input.roundDate.open"
          class="form-control"
        />
      </b-form-group>

      <b-form-group
        label="เวลาปิดแทง"
      >
        <flat-pickr
          id="round-close-time"
          :config="{enableTime: true, enableSeconds: false, time_24hr: true, dateFormat: 'Y-m-d H:i:S'}"
          v-model="input.roundDate.close"
          class="form-control"
        />
      </b-form-group>

      <!-- <b-form-group label="การบันทึกโพย" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="input.recording.recordType"
          :options="recordOptions"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group v-if="input.recording.recordType==='pause'" label="ข้อความแจ้ง Member">
        <b-form-input
          v-model="input.recording.note"
          type="text"
          placeholder="ข้อความ Popup แจ้ง Member"
        ></b-form-input>
      </b-form-group> -->

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>

      <loading
        :active="isProcess"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </form>
  </b-modal>
</template>
<script>
import Swal from 'sweetalert2'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import LottoService from '@/services/lottoService'

const defaultInput = {
  _id: null,
  roundDate: {
    date: null,
    open: null,
    close: null
  },
  recording: {
    recordType: 'fast',
    note: ''
  }
}

export default {
  name: 'SettingRoundModal',
  components: {
    flatPickr,
    Loading
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      input: JSON.parse(JSON.stringify(defaultInput)),
      isProcess: false,
      needReload: false,
      recordOptions: [
        { text: 'บันทึกเร็ว', value: 'fast' },
        { text: 'ต่อคิว', value: 'queue' },
        { text: 'หยุดรับชั่วคราว', value: 'pause' },
      ]
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.input = JSON.parse(JSON.stringify(this.data))
    },
    showModal() {
      this.$refs['setting-round-modal'].show()
    },
    hideModal() {
      this.$refs['setting-round-modal'].hide()
    },
    handleSubmit() {
      console.log('submit!')
      this.isProcess = true
      LottoService.updateRound(this.data._id, {
        roundDate: this.input.roundDate,
        recording: this.input.recording
      })
      .then((response)=>{
        console.log(response)
        if(response.success) {
          Swal.fire({
            text: 'บันทึกเสร็จเรียบร้อย',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          .then(()=>{

            this.$store.commit('updateGlobalMarket', {
              groupId: response.data.groupId,
              marketId: response.data.marketId,
              round: {
                roundId: response.data.id,
                roundDate: response.data.roundDate
              }
            })

            this.needReload = true
            this.hideModal()
          })
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
